import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as heroVidStyles from "./hero-video.module.css"
import bgVideo from "../video/Header-Background-1.mp4"

const HeroVideo = () => {
  let data = useStaticQuery(graphql`{
  file(relativePath: {regex: "/Ngan_Mythren_Promo_art.jpg/"}) {
    childImageSharp {
      gatsbyImageData(height: 600, quality: 100, layout: FULL_WIDTH)
    }
  }
}
`)
  return (
    <video
      className={heroVidStyles.bgvideo}
      playsInline
      autoPlay
      muted
      loop
      poster={data.file.childImageSharp.gatsbyImageData.src}
    >
      <source src={bgVideo} type="video/mp4" />
    </video>
  );
}

export default HeroVideo
