import React from "react"
import * as shStyles from "./section-header.module.css"

const SectionHeader = ({ text, type }) => (
  <div>
    <div
      id={text.split(" ")[0].toLowerCase()}
      className={shStyles.headerContainer}
    >
      <div className={shStyles.headerAlignBox}>
        <div className={shStyles.sectionHeader}>
          <h2 className={shStyles.sectionHeaderText}>{text}</h2>
          <svg className={shStyles.svg}>
            <clipPath
              id="section-header-clip"
              clipPathUnits="objectBoundingBox"
            >
              <path d="M0,0 V0.076 L0.078,0.091,0.084,0.127,0,0.108 V1 L0.1,0.874 L0.122,1 H0.962 L0.992,0.874,1,0.325,0.917,0.181"></path>
            </clipPath>
          </svg>
        </div>
      </div>
    </div>
    <Divider type={type} />
  </div>
)

const Divider = ({ type }) => {
  if (type === "EndGreen")
    return (
      <div className={shStyles.bgContainer2}>
        <div className={shStyles.bg2}></div>
        <svg className={shStyles.svg}>
          <clipPath id="bg-2-clip" clipPathUnits="objectBoundingBox">
            <path
              transform="scale(5.211047420531527e-4, 0.0052616624748756)"
              d="M 0 0 H 1919 l 1.1 190.054 l -65.66 -34.4 l -50.98 16.694 l -62.69 -13.142 L 1717.266 142.87 l -126.508 0.161 L 1563 123.44 l -39.9 29.725 l -36.733 -15.087 L 1187.294 124.024 l -15.048 -15 L 1005.61 102.413 L 996.82 132.14 L 618.387 99.162 L 598.2 63.658 L 537.4 95.014 l -92.392 -3.795 L 374.414 108.155 L 228.092 103.849 L 189.935 79.54 l -76.448 -3.5 L 0 70.834 Z"
            ></path>
          </clipPath>
        </svg>
      </div>
    )
  else
    return (
      <div className={shStyles.bgContainer1}>
        <div className={shStyles.bg1}></div>
        <svg className={shStyles.svg}>
          <clipPath id="bg-1-clip" clipPathUnits="objectBoundingBox">
            <path d="M0,1 H1 V0.992 L-0.034,0.84 L0.94,0.992 L0.907,0.872 L0.895,0.723,0.806,0.781 L0.793,0.579,0.775,0.555,0.736,0.723,0.716,0.623,0.619,0.551,0.611,0.414 L0.524,0.354,0.517,0.525,0.322,0.324,0.312,0,0.28,0.286 L0.232,0.252 L0.195,0.406,0.119,0.367,0.099,0.145 L0.059,0.113 L0,0.066"></path>
          </clipPath>
        </svg>
      </div>
    )
}

export default SectionHeader
