// extracted by mini-css-extract-plugin
export var cardBody = "character-grid-icon-module--card-body--6867f";
export var characterArt = "character-grid-icon-module--character-art--6e952";
export var characterBorderSvg = "character-grid-icon-module--character-border-svg--5168c";
export var characterClip = "character-grid-icon-module--character-clip--bd7a3";
export var characterContainer = "character-grid-icon-module--character-container--4c24a";
export var characterModal = "character-grid-icon-module--character-modal--44429";
export var characterModalOverlay = "character-grid-icon-module--character-modal-overlay--5fbe5";
export var characterName = "character-grid-icon-module--character-name--0bd42";
export var characterNameBox = "character-grid-icon-module--character-name-box--855dd";
export var characterNameOverlay = "character-grid-icon-module--character-name-overlay--39880";
export var characterOverlayGrid = "character-grid-icon-module--character-overlay-grid--b83b2";
export var closeButton = "character-grid-icon-module--close-button--de8ae";
export var closeButtonImage = "character-grid-icon-module--close-button-image--280fc";
export var descriptionBox = "character-grid-icon-module--description-box--f4945";
export var descriptionSection = "character-grid-icon-module--description-section--102e5";
export var imageSection = "character-grid-icon-module--image-section--8b4ea";
export var statsText = "character-grid-icon-module--stats-text--c2b38";
export var topBar = "character-grid-icon-module--top-bar--3ef0d";
export var triangleContainer = "character-grid-icon-module--triangle-container--2d6ef";
export var triangleSvg = "character-grid-icon-module--triangle-svg--ea83f";