// extracted by mini-css-extract-plugin
export var aboutSection = "index-module--about-section--4b878";
export var bigGap = "index-module--big-gap--3b5ff";
export var cartoonTitle = "index-module--cartoon-title--1775f";
export var discordFrame = "index-module--discord-frame--5f453";
export var emptyBox = "index-module--empty-box--cad8f";
export var greenBox = "index-module--green-box--3734e";
export var mythrenGrid = "index-module--mythren-grid--4d4a2";
export var mythrenGridContainer = "index-module--mythren-grid-container--96e7a";
export var mythrenGridPaddingBox = "index-module--mythren-grid-padding-box--8d660";
export var newsGrid = "index-module--news-grid--309e3";
export var pressBox = "index-module--press-box--405e3";
export var pressButton = "index-module--press-button--135bc";
export var pressButtonContainer = "index-module--press-button-container--c9675";
export var smallHeader = "index-module--small-header--58c62";
export var socialBox = "index-module--social-box--f6bc4";
export var socialSidebar = "index-module--social-sidebar--228dd";
export var twitterBox = "index-module--twitter-box--f8ebe";