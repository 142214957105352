import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as cardStyle from "./news-card.module.css"

const NewsCard = ({ post }) => {
  const placeholderData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "environment-screenshot.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            height: 235
            transformOptions: { cropFocus: ENTROPY }
            layout: CONSTRAINED
          )
        }
      }
    }
  `)

  let featuredImg = (
    <GatsbyImage
      image={
        post.frontmatter.featuredImage
          ? post.frontmatter.featuredImage.childImageSharp.gatsbyImageData
          : placeholderData.file.childImageSharp.gatsbyImageData
      }
      className={cardStyle.image}
    />
  )

  return (
    <Link className={cardStyle.link} to={post.frontmatter.slug}>
      <div className={cardStyle.box}>
        {featuredImg}
        <div className={cardStyle.linkBox}>
          {post.frontmatter.title} ({post.frontmatter.date})
        </div>
      </div>
    </Link>
  )
}
export default NewsCard
