import React from "react"
import { /* Link, */ graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/header-hero"
import SEO from "../components/seo"
import SectionHeader from "../components/section-header"
import NewsCard from "../components/news-card"
import CharacterIcon from "../components/character-grid-icon"

import * as indexStyle from "./index.module.css"
import * as layoutStyle from "../components/layout.module.css"

const IndexPage = ({ data }) => (
  <div>
    <Hero />
    <Layout>
      <SEO title="Home" />
      <About />
      <News data={data} />
      <Characters data={data} />
      <Social />
      <Press />
    </Layout>
  </div>
)

const About = () => (
  <div>
    <div className={layoutStyle.container}>
      <div className={indexStyle.aboutSection}>
        <h2
          id="about"
          className={`${indexStyle.smallHeader} ${indexStyle.cartoonTitle}`}
        >
          What is Mythren?
        </h2>
        <p>
          Inspired by both Persona and Pokemon, Mythren looks to bring a unique
          twist to the monster taming genre!
        </p>
        <p>
          Mythren is a day-to-day Social Sim RPG starring monsters who you
          befriend known as Mythren! You will begin your journey as a newly
          acquainted tamer. As a tamer, you must bond, explore, train, and fight
          with your Mythren to face the many trials ahead of you on your
          journey.
        </p>
        <p>
          Meet new Mythren, fight trainers, socialize, and participate in
          tournaments. So many things to do in so little time. What will you
          choose to do on each given day?
        </p>
      </div>
    </div>
  </div>
)

const News = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => edge.node.fields.collection === "blog")
    .filter(edge => !!edge.node.frontmatter.date)
    // Key is not currently used inside NewsCard - maybe remove
    .map(edge => <NewsCard key={edge.node.id} post={edge.node} />)

  return (
    <div>
      <SectionHeader text="NEWS" />
      <div className={indexStyle.greenBox}>
        <div className={indexStyle.newsGrid}>{Posts}</div>
      </div>
    </div>
  )
}

const Characters = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const MythrenList = edges
    .filter(edge => edge.node.fields.collection === "characters")
    .sort((edgeA, edgeB) => {
      const a_id = edgeA.node.frontmatter.id
      const b_id = edgeB.node.frontmatter.id

      if (!a_id) return -1
      if (!b_id) return 1
      return a_id - b_id
    })
    .map(edge => <CharacterIcon node={edge.node} />)

  return (
    <div>
      <SectionHeader text="MYTHREN" type="EndGreen" />

      <div className={indexStyle.mythrenGridPaddingBox}>
        <div className={indexStyle.mythrenGridContainer}>
          {/* <h2 className={indexStyle.cartoonTitle}>Meet the Mythren</h2>
          <p className={indexStyle.mythrenGridFlavorText}>
            Along your journey, you will be accompanied by several monsters
            known as Mythren, each with unique personalities, stories, and
            abilities.
          </p> */}
          <div className={indexStyle.mythrenGrid}>{MythrenList}</div>
        </div>
      </div>
    </div>
  )
}

const Social = () => (
  <div>
    <div>
      <SectionHeader text="SOCIAL" />
    </div>
    <div className={indexStyle.greenBox}>
      <div className={indexStyle.socialBox}>
        <div className={indexStyle.socialSidebar}>
          <h3 className={indexStyle.cartoonTitle}>Discord</h3>
          <p>
            Come join our community on Discord and get notified when new tweets
            and newsletters are posted! You can also chat with the developers
            and participate in Q&A’s.
          </p>
          <iframe
            title="Discord Widget"
            className={indexStyle.discordFrame}
            src="https://ptb.discordapp.com/widget?id=732307723504582696&theme=dark"
            width="100%"
            height="553"
            allowtransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </div>
        <div className={indexStyle.twitterBox}>
          <a
            className="twitter-timeline"
            data-width="500"
            data-height="803"
            href="https://twitter.com/MythrenGame?ref_src=twsrc%5Etfw"
          >
            Tweets by MythrenGame
          </a>
        </div>
      </div>
    </div>
  </div>
)

const Press = () => (
  <div>
    <div>
      <SectionHeader text="PRESS KIT" type="EndGreen" />
    </div>
    <div className={layoutStyle.container}>
      <div className={indexStyle.pressBox}>
        <a href="/press" className={indexStyle.pressButton}>
          Press Kit / Contact
        </a>
        {/* <a href="/press" className={indexStyle.pressButton}>
          Contact Us
        </a> */}
      </div>
    </div>
  </div>
)

export default IndexPage

export const pageQuery = graphql`
  {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            collection
          }
          id
          html
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  height: 235
                  transformOptions: { cropFocus: ENTROPY }
                  layout: CONSTRAINED
                )
              }
            }
            headshot {
              childImageSharp {
                gatsbyImageData(
                  width: 175
                  height: 235
                  transformOptions: { cropFocus: ENTROPY }
                  layout: CONSTRAINED
                )
              }
            }
            name
            types
            photo {
              childImageSharp {
                gatsbyImageData(
                  transformOptions: { cropFocus: ENTROPY }
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
              absolutePath
            }
            emblem {
              childImageSharp {
                gatsbyImageData(
                  width: 175
                  height: 220
                  placeholder: BLURRED
                  transformOptions: { cropFocus: ENTROPY }
                  layout: CONSTRAINED
                )
              }
              publicURL
            }
            combat_style
            id
          }
        }
      }
    }
  }
`
