import React from "react"

import HeroLogo from "../images/mythren-logo-kickstarter-small.png"
import * as layoutStyles from "./layout.module.css"

import HeroVideo from "./hero-video.js"
import KickstarterImage from "../images/kickstarter-logo-white.png"
import { GatsbyImage } from "gatsby-plugin-image";
import { FaChevronRight } from "react-icons/fa"

const Hero = () => {
  return (
    <div className={layoutStyles.heroSection}>
      <HeroVideo />
      <div className={layoutStyles.heroGrid}>
        <img className={layoutStyles.heroLogo} src={HeroLogo} alt="" />
        <div className={layoutStyles.heroSubtitle}>
          <span>
            Explore, Fight, and Bond with your Mythren in this Monster Taming
            Action RPG.
          </span>
        </div>
        <div className={layoutStyles.heroLinkBox}>
          <div className={layoutStyles.heroButtonBorder}>
            <form action="https://www.kickstarter.com/projects/mythren/mythren-a-monster-taming-action-rpg">
              <button type="submit" className={layoutStyles.heroButton}>
                <span className={layoutStyles.heroButtonText}>
                  SUPPORT US ON
                </span>
                <img
                  className={layoutStyles.kickstarterLogo}
                  src={KickstarterImage}
                  alt="Kickstarter"
                />
                <FaChevronRight className={layoutStyles.heroChevron} />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
