import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import * as charStyle from "./character-grid-icon.module.css"
import Modal from "react-modal"
import { MdClose } from "react-icons/md"
import SimpleBar from "simplebar-react"
import "simplebar/dist/simplebar.min.css"

Modal.setAppElement("#___gatsby")

const CharacterModal = ({
  frontmatter: { name, types, photo, emblem, combat_style },
  html,
  closeModal,
}) => {
  return (
    <div className={charStyle.characterOverlayGrid}>
      <div className={charStyle.topBar}>
        <h1 className={charStyle.characterNameOverlay}>{name}</h1>
        <button
          type="button"
          className={charStyle.closeButton}
          onClick={closeModal}
        >
          <MdClose className={charStyle.closeButtonImage} />
        </button>
      </div>
      <div className={charStyle.descriptionSection}>
        <SimpleBar style={{ height: "100%" }}>
          <div className={charStyle.descriptionBox}>
            <p className={charStyle.statsText}>Type: {types.join(", ")}</p>
            <p className={charStyle.statsText}>Combat Style: {combat_style}</p>
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </SimpleBar>
      </div>
      <div className={charStyle.imageSection}>
        <GatsbyImage
          image={photo.childImageSharp.gatsbyImageData}
          className={charStyle.characterArt}
          objectFit="scale-down" />
      </div>
      <div className={charStyle.triangleContainer}>
        <svg
          className={charStyle.triangleSvg}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <polygon points="0,100 0,0 100,100" />
        </svg>
      </div>
    </div>
  );
}

const CharacterIcon = ({ node: { frontmatter, html } }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const openModal = () => {
    document.body.style.overflow = "hidden"
    document.body.style.paddingRight = "16px"
    setIsOpen(true)
  }
  const closeModal = () => {
    document.body.style.overflow = "visible"
    document.body.style.paddingRight = "0px"
    setIsOpen(false)
  }

  return (
    <div>
      <div className={charStyle.characterContainer} onClick={openModal}>
        {/* 
        // this border isnt working :')
        <svg
          className={charStyle.characterBorderSvg}
          preserveAspectRatio="none"
          height="100"
          width="100"
          viewBox="0 0 100 100"
        >
          <polyline
            points="0,0 0,100 90,100 100,97 100,0"
            stroke="#FF0000"
            stroke-width="2px"
            fill="none"
          />
        </svg> */}
        <div className={charStyle.characterClip}>
          <GatsbyImage
            image={frontmatter.headshot.childImageSharp.gatsbyImageData}
            className={charStyle.image} />
          <div className={charStyle.characterNameBox}>
            <span className={charStyle.characterName}>{frontmatter.name}</span>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={charStyle.characterModal}
        overlayClassName={charStyle.characterModalOverlay}
      >
        <CharacterModal
          frontmatter={frontmatter}
          html={html}
          closeModal={closeModal}
        ></CharacterModal>
      </Modal>
    </div>
  );
}

export default CharacterIcon
